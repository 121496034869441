<template>
    <div>
        <!-- 录入投诉案件 弹窗 -->
        <el-dialog title="录入投诉案件" :visible.sync="dialogEnterFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="900px" top="5vh" @close="closeDiag">
            <div class="dialog_content">
                <el-form :model="addCaseForm" :rules="rules" ref="ruleForm" label-width="110px">
                    <div class="form_content">
                        <el-row>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="投诉编号：" prop="complaintNumber">
                                    <el-input v-model.trim="addCaseForm.complaintNumber" :class="{ errorBorder: sameComplaintNo_ErrorMessage }"></el-input>
                                    <div class="el-form-item__error" v-if="sameComplaintNo_ErrorMessage">投诉编号已存在</div>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="保单号：" prop="policyNo">
                                    <el-input v-model.trim="addCaseForm.policyNo" maxlength="50"></el-input>
                                    <div class="warning-message" v-if="policyNoError">{{ policyNoError }}</div>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="受理渠道：" prop="complaintChannel">
                                    <el-select v-model="addCaseForm.complaintChannel" filterable clearable placeholder="请选择" @change="channelChange">
                                        <el-option v-for="item in channelOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="投诉人：" prop="complaintPerson">
                                    <el-input v-model.trim="addCaseForm.complaintPerson"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="投诉人身份：" prop="complaintIdCard">
                                    <el-input v-model.trim="addCaseForm.complaintIdCard" maxlength="11"></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')" -->
                            <el-col class="title_col" :span="8">
                                <el-form-item label="投诉电话：" prop="complaintPhone">
                                    <el-input v-model="addCaseForm.complaintPhone" maxlength="11" oninput="value=value.replace(/\D/g, '')"></el-input>
                                    <div class="warning-message" v-if="complaintPhoneError">{{ complaintPhoneError }}</div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="诉求原因：" prop="complaintCause">
                                    <el-select v-model="addCaseForm.complaintCause" filterable clearable placeholder="请选择">
                                        <el-option v-for="item in reasonOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="登记日期：" prop="recordDay">
                                    <el-date-picker v-model="addCaseForm.recordDay" placeholder="请选择日期" clearable type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="24">
                                <el-form-item label="投诉内容：" prop="complaintContent">
                                    <el-input class="textareaNumPosition" v-model="addCaseForm.complaintContent" type="textarea" :autosize="{ minRows: 2 }" maxlength="255" show-word-limit placeholder="请输入内容"> </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="责任人：" prop="liablePerson">
                                    <el-select v-model="addCaseForm.liablePerson" placeholder="请选择" filterable clearable @change="personChange">
                                        <el-option v-for="item in complaintCaseUserOptions" :key="item.userId" :label="item.nickName" :value="item.nickName + '_' + item.deptId + '_' + item.userName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="涉诉机构：" prop="complaintOrganization">
                                    {{ addCaseForm.complaintOrganization ? addCaseForm.complaintOrganization : '--' }}
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="升级情况：" prop="upgradeSituation">
                                    <el-select v-model="addCaseForm.upgradeSituation" filterable clearable placeholder="请选择">
                                        <el-option v-for="(item, index) in upgradeOptions" :key="index" :label="item.dictName" :value="item.dictName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="涉诉省：" prop="province">
                                    <el-select v-model="addCaseForm.province" filterable clearable placeholder="请选择" @change="enterProvinceChange">
                                        <el-option v-for="item in provinceOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col class="title_col" :span="8">
                                <el-form-item label="涉诉市：" prop="province">
                                    <el-select v-model="addCaseForm.city" filterable clearable placeholder="请选择">
                                        <el-option v-for="item in cityOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName"> </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="title_col" :span="24">
                                <el-form-item label="备注：" prop="remark">
                                    <el-input class="textareaNumPosition" v-model="addCaseForm.remark" type="textarea" :autosize="{ minRows: 4 }" maxlength="255" show-word-limit placeholder="请输入内容"> </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="enterCase(true)">创 建</el-button>
                <el-button @click="enterCase(false)">取 消</el-button>
                <el-button @click="tianxie" v-if="0">调 试</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';

let queryCaseRuleFlag = false;
const validatePass = (rule, value, callback) => {
    if (value.length > 0 && queryCaseRuleFlag) {
        callback(new Error('未查询到案件号，请输入正确的理赔报案号重新查询'));
    } else {
        callback();
    }
};
export default {
    props: {
        dialogShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            baseURL: '',
            dialogEnterFlag: false,
            currentFileIndex: -1,
            policyNoError: '',
            complaintPhoneError: '',
            sameComplaintNo_ErrorMessage: '',
            addCaseForm: {
                complaintNumber: '',
                complaintChannel: '',
                policyNo: '',
                complaintPerson: '',
                complaintIdCard: '',
                complaintPhone: '',
                complaintContent: '',
                complaintCause: '',
                recordDay: '',
                complaintOrganization: '',
                liablePerson: '',
                checkIndemnityPerson: '',
                province: '',
                city: '',
                upgradeSituation: '未升级',
                closeCaseFlag: '',
                remark: ''
            },
            channelOptions: [],
            reasonOptions: [],
            booleanOptions: [
                {
                    label: '是',
                    code: true
                },
                {
                    label: '否',
                    code: false
                }
            ],
            province: '',
            provinceOptions: [],
            city: '',
            cityOptions: [],
            district: '',
            districtOptions: [],
            cityDisabled: true,
            districtDisabled: true,
            causeOptions: [],
            productTypeOptions: [],
            insureTypeOptions: [],
            allocationUserOptions: [],
            allocationUserObj: {},
            caseFileTypeOptions: [],
            caseFileTypeObj: {},
            fileTypeList: ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'],
            num: 0,
            checkPhoneApi: true,
            upgradeOptions: [],
            provinceObj: {},
            cityOptions: [],
            complaintCaseUserOptions: [],
            complaintCaseUserObj: {},
            sysDeptObj: {},
            sysDept: []
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
        // 获取渠道
        this.getPublicEnum('COMPLAINT_CHANNEL_INFO', 'channelOptions');
        // 获取投诉原因
        this.getPublicEnum('COMPLAINT_CAUSE_INFO', 'reasonOptions');
        // 获取升级情况
        this.getPublicEnum('COMPLAINT_UPGRADE_SITUATION', 'upgradeOptions');
        this.getPublicEnum();
        // 查询省市
        this.findProvince();
        // 部门
        this.findSysDept();
        // 责任人
        this.getComplaintCaseUser();
    },
    computed: {
        // 自定义表单规则
        rules() {
            let phoneRules = [
                { validator: this.phoneNoValidator, trigger: ['blur'] },
                { required: true, message: '请输入', trigger: ['blur'] },
                { min: 11, max: 11, message: '电话长度为11位', trigger: ['blur'] }
            ];

            let policyNoRules = [
                { validator: this.policyNoValidator, trigger: ['blur'] },
                { required: true, message: '请输入', trigger: ['blur'] }
            ];

            if (!this.checkPhoneApi) {
                phoneRules = [
                    { required: true, message: '请输入', trigger: ['blur'] },
                    { min: 11, max: 11, message: '电话长度为11位', trigger: ['blur'] }
                ];

                policyNoRules = [{ required: true, message: '请输入', trigger: ['blur'] }];
            }

            return {
                complaintNumber: [
                    { validator: this.clearComplaintErrorMessage, trigger: ['change'] },
                    { required: true, message: '请输入', trigger: ['blur', 'change'] }
                ],
                complaintChannel: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                complaintPerson: [{ required: true, message: '请输入', trigger: ['blur', 'change'] }],
                complaintIdCard: [{ required: true, message: '请输入', trigger: ['blur', 'change'] }],
                complaintPhone: phoneRules,
                complaintContent: [{ required: true, message: '请输入', trigger: ['blur', 'change'] }],
                complaintCause: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                recordDay: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                // complaintOrganization: [{ required: true, message: '请输入', trigger: ['blur', 'change'] }],
                // liablePerson: [{ required: true, message: '请输入', trigger: ['blur', 'change'] }],
                checkIndemnityPerson: [{ required: true, message: '请输入', trigger: ['blur', 'change'] }],
                // province: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                // upgradeSituation: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                closeCaseFlag: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                policyNo: policyNoRules
            };
        }
    },
    methods: {
        tianxie() {
            console.log('---');
            console.log(this.num);
            this.num++;
            if (this.num > 2) {
                this.num = 0;
                this.addCaseForm = {
                    complaintNumber: '',
                    complaintChannel: '',
                    policyNo: '',
                    complaintPerson: '',
                    complaintIdCard: '',
                    complaintPhone: '',
                    complaintContent: '',
                    complaintCause: '',
                    recordDay: '',
                    complaintOrganization: '',
                    liablePerson: '',
                    checkIndemnityPerson: '',
                    province: '',
                    upgradeSituation: '',
                    closeCaseFlag: '',
                    remark: ''
                };
            }
            if (this.num > 0) {
                this.addCaseForm = {
                    complaintNumber: '1',
                    complaintChannel: '95500工单',
                    policyNo: '1',
                    complaintPerson: '1',
                    complaintIdCard: '1',
                    complaintPhone: '17716532122',
                    complaintContent: '1',
                    complaintCause: '1',
                    recordDay: '2024-10-18',
                    complaintOrganization: '1',
                    liablePerson: '1',
                    checkIndemnityPerson: '1',
                    province: '1',
                    upgradeSituation: '未升级',
                    closeCaseFlag: true,
                    remark: ''
                };
            }
        },
        findSysDept() {
            this.instance.post('/complaintInfo/findSysDept ', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.sysDept = data.data;
                    data.data &&
                        data.data.forEach((item) => {
                            this.sysDeptObj[item.deptId] = item.deptName;
                        });
                }
            });
        },
        getComplaintCaseUser() {
            this.instance.post('/complaintInfo/distributeComplaintCaseUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.complaintCaseUserOptions = data.data;
                    this.complaintCaseUserOptions.forEach((item) => {
                        this.complaintCaseUserObj[item.userName] = item.nickName;
                    });
                }
            });
        },
        personChange(val) {
            if (!val) {
                this.addCaseForm.complaintOrganization = '';
                this.addCaseForm.liablePersonUser = '';
                this.addCaseForm.liablePerson = '';
                return;
            }

            let valArr = val.split('_');
            if (valArr.length < 2) {
                this.addCaseForm.complaintOrganization = '';
                this.addCaseForm.liablePersonUser = '';
                this.addCaseForm.liablePerson = valArr[0];
                return;
            }
            this.addCaseForm.liablePerson = valArr[0];
            this.addCaseForm.complaintOrganization = this.sysDeptObj[valArr[1]];
            this.addCaseForm.liablePersonUser = valArr[2];
        },
        findProvince() {
            this.instance.post('/area/findProvince', {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.provinceOptions = data.data;
                    this.provinceObj = {};
                    data.data.forEach((item) => {
                        this.provinceObj[item.areaName] = item.areaCode;
                    });
                }
            });
        },
        findCity() {
            let code = this.addCaseForm.province.split('-')[1];
            this.instance.post('/area/findCity/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.cityDisabled = false;
                    this.districtDisabled = false;
                    this.cityOptions = data.data;
                }
            });
        },
        findDistrict() {
            let code = this.addCaseForm.city.split('-')[1];
            this.instance.post('/area/findDistrict/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.districtDisabled = false;
                    this.districtOptions = data.data;
                }
            });
        },
        provinceChange1(val) {
            this.addCaseForm.city = '';
            this.addCaseForm.district = '';
            this.cityDisabled = true;
            this.districtDisabled = true;
            if (val) {
                this.findCity();
            }
        },
        provinceChange2(val) {
            this.addCaseForm.district = '';
            this.districtDisabled = true;
            if (val) {
                this.findDistrict();
            }
        },
        currentData(currentItem, currentIndex) {
            this.currentFileIndex = currentIndex;
        },
        getCaseFileType() {
            this.instance.get('/lawsuitCase/material/getCaseFileType', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseFileTypeObj = {};
                    this.caseFileTypeOptions = data.data;
                    data.data.forEach((item) => {
                        this.caseFileTypeObj[item.code] = item.name;
                    });
                }
            });
        },
        getCause() {
            this.instance.get('/lawsuitCase/getCause', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.causeOptions = data.data;
                }
            });
        },
        getProductType() {
            this.instance.get('/lawsuitCase/getProductType', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.productTypeOptions = data.data;
                }
            });
        },
        getInsureTypeOptions() {
            this.instance.get('/lawsuitCase/getInsureTypeName', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.insureTypeOptions = data.data;
                }
            });
        },
        allocationUserChange(val) {
            this.addCaseForm.distributeHandler = this.allocationUserObj[val] || '';
        },
        enterCase(submitFlag) {
            if (submitFlag) {
                this.checkPhoneApi = false;
                setTimeout(() => {
                    this.$nextTick(() => {
                        this.$refs.ruleForm.validate((valid) => {
                            valid && this.addNewCase();
                        });
                    });
                }, 200);
            } else {
                this.closeDiag();
            }
        },
        closeDiag() {
            this.$emit('switchDialog', false);
            this.resetCaseData();
        },
        resetCaseData() {
            this.addCaseForm = {
                complaintNumber: '',
                complaintChannel: '',
                policyNo: '',
                complaintPerson: '',
                complaintIdCard: '',
                complaintPhone: '',
                complaintContent: '',
                complaintCause: '',
                recordDay: '',
                complaintOrganization: '',
                liablePerson: '',
                checkIndemnityPerson: '',
                province: '',
                upgradeSituation: '',
                closeCaseFlag: '',
                remark: ''
            };
        },
        addNewCase() {
            this.instance.post('/complaintInfo/addComplaint', this.addCaseForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('录入案件成功！');
                    this.$emit('searchCasePage', true);
                    this.closeDiag();
                } else {
                    this.$message.error(data.message);
                    if (data.message == '投诉编号已存在!') {
                        this.sameComplaintNo_ErrorMessage = data.message;
                    }
                }
            });
        },
        channelChange(val) {
            if (!val) {
                this.addCaseForm.upgradeSituation = '未升级';
                return;
            }

            this.instance.post('/complaintInfo/findExistsComplaint', this.addCaseForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.setEnterForm(data.data);
                }
            });
        },
        //自定义的保单号校验规则
        policyNoValidator(rule, value, callback) {
            if (value) {
                this.instance.post('/complaintInfo/findExistsComplaint', this.addCaseForm).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.setEnterForm(data.data);
                        let policyNoNum = data.data.policyNoNum;
                        if (policyNoNum > 0) {
                            this.policyNoError = '保单号已存在，数量' + policyNoNum;
                            callback();
                        } else {
                            this.policyNoError = '';
                            callback();
                        }
                    }
                });
            } else {
                this.policyNoError = '';
                callback();
            }
        },
        setEnterForm(formData) {
            if (!formData) {
                return;
            }

            // 涉诉机构
            this.addCaseForm.complaintOrganization = formData.complaintOrganization;
            // 责任人
            this.addCaseForm.liablePerson = formData.liablePerson;
            // 升级情况
            this.addCaseForm.upgradeSituation = formData.upgradeSituation;
            // 省
            this.addCaseForm.province = formData.accidentProvince;
            // 市
            setTimeout(() => {
                this.addCaseForm.city = formData.accidentCity;
            }, 100);
        },
        //自定义的电话号校验规则
        phoneNoValidator(rule, value, callback) {
            if (value && value.length == 11) {
                this.instance.post('/complaintInfo/findExistsComplaint', this.addCaseForm).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.setEnterForm(data.data);
                        let PhoneNoNum = data.data.complaintPhoneNum;
                        if (PhoneNoNum > 0) {
                            this.complaintPhoneError = '手机号已存在，数量' + PhoneNoNum;
                            callback();
                        } else {
                            this.complaintPhoneError = '';
                            callback();
                        }
                    }
                });
            } else {
                this.complaintPhoneError = '';
                callback();
            }
        },
        //清除重复投诉编号报错信息
        clearComplaintErrorMessage(rule, value, callback) {
            this.sameComplaintNo_ErrorMessage = '';
            callback();
        },
        findCityChange() {
            this.cityOptions = [];
            let code = this.provinceObj[this.addCaseForm.province];
            this.instance.post('/area/findCity/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.cityOptions = data.data;
                }
            });
        },
        enterProvinceChange(val) {
            this.addCaseForm.city = '';
            this.findCityChange();
        }
    },
    watch: {
        dialogShow: {
            handler(val) {
                this.dialogEnterFlag = val;
                // val && this.initOSSClient();
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_public.css';
// 弹窗
.dialog_content {
    margin-top: -10px;
    .query_case_form {
        /deep/ .form_item {
            .el-form-item__label {
                white-space: nowrap;
                margin-left: -11px;
            }
        }
    }
    .case_show {
        margin-bottom: 20px;
        .title_col {
            .left_col {
                .query_title {
                    width: 138px !important;
                }
            }
        }
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .el-row {
        margin-bottom: 20px;
        .title_col {
            .txt_box {
                display: inline-block;
            }
            .info_title {
                width: 100px;
                text-align: right;
                // border: 1px solid red;
            }
            .query_title {
                width: 128px;
            }
            /deep/ .shengfen {
                .el-form-item__content {
                    margin-left: 0 !important;
                    position: relative;
                }
            }
            .warning-message {
                position: absolute;
                top: 25px;
                color: orange;
                font-size: 12px;
            }
        }
    }
    .el-row:last-child {
        margin-bottom: 0;
    }
    .form_item {
        margin-bottom: 20px;
        .el-row {
            margin-bottom: 0px;
        }
    }
    .exist_case {
        /deep/ .el-input__inner {
            border: 1px solid #e6a23c;
        }
        .exist_col {
            position: relative;
            .exist_message {
                color: #e6a23c;
                font-size: 12px;
                position: absolute;
                bottom: -27px;
            }
        }
    }
    .form_content {
        padding: 0 10px;
    }
    .case_entry {
        margin-bottom: 20px;
    }
    .updateFile {
        position: relative;
        left: -2px;
        .el-col {
            line-height: 28px;
            position: relative;
            .del_btn {
                font-size: 20px;
                cursor: pointer;
            }
            .disabledBtn {
                width: 25px;
                height: 25px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                cursor: not-allowed;
            }
            .errorBtn {
                color: red;
            }
            .tooltipText {
                // width: 90%;
            }
            .progressSpan {
                position: absolute;
                top: 0;
                right: -36px;
                color: #409eff;
            }
        }
    }
    .uploadBtn {
        position: relative;
        left: -2px;
    }

    .preview_file {
        position: relative;
        .preview_image {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            /deep/ img {
                opacity: 0 !important;
            }
        }
    }
    // .province_item {
    //     /deep/ .el-form-item__content {
    //         // margin-left: 0 !important;
    //         // margin-left: 5px !important;
    //         .selectProvince {
    //             width: 30%;
    //             margin-right: 27px;
    //         }
    //         .selectProvince:last-child {
    //             margin-right: 0;
    //         }
    //         .el-form-item__error {
    //         }
    //     }
    // }
    .province_box {
        display: flex;
        justify-content: space-between;
        .province_item {
            width: 45%;
        }
        .province_item:nth-child(2) {
            width: 25%;
            margin-left: 0px;
            /deep/ .el-form-item__content {
                margin-left: 0 !important;
            }
        }
        .province_item:nth-child(3) {
            width: 25%;
            margin-left: 0px;
            /deep/ .el-form-item__content {
                margin-left: 0 !important;
            }
        }
    }
}
.errorBorder {
    /deep/ .el-input__inner {
        border: 1px solid red;
    }
}

.textareaNumPosition {
    /deep/ .el-input__count,
    .el-input__count,
    /deep/ span {
        background: none !important;
        bottom: -25px !important;
        right: 3px !important;
    }
}
</style>