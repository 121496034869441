<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">处理情况</div>
            </el-col>
        </el-row>
        <div class="card_content">
            <el-form class="submit_form" ref="submitRef" :model="submitForm" :rules="submitRules">
                <template v-if="btnMenuData && btnMenuData[400]">
                    <el-row class="content_row" style="margin-bottom: 10px">
                        <el-col class="col_txt personCol" :span="7">
                            <span style="margin-top: 7px">是否办结：</span>
                            <!-- <el-radio v-model="editForm.closeCaseFlag" :label="true" @input="(e) => editCaseDetail(null, e)">是</el-radio> -->
                            <!-- <el-radio v-model="editForm.closeCaseFlag" :label="false" @input="(e) => editCaseDetail(null, e)">否</el-radio> -->
                            <el-form-item label="">
                                <el-radio v-model="editForm.closeCaseFlag" :label="true" @input="(e) => editCaseDetail(null, e)">是</el-radio>
                                <el-radio v-model="editForm.closeCaseFlag" :label="false" @input="(e) => editCaseDetail(null, e)">否</el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col class="col_txt personCol" :span="7" :offset="2" v-if="0">
                            <div class="personDiv"><span class="fontSeat">占</span><span>核赔人：</span></div>
                            <!-- checkIndemnityPerson -->
                            <el-form-item class="personFormItem" label="" prop="">
                                <el-input v-model="editForm.checkIndemnityPerson" placeholder="请输入内容" @blur="(e) => editCaseDetail(null, e)"> </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="situation">
                        投诉人来电投诉“{{ caseDetailData && caseDetailData.complaintCause ? caseDetailData.complaintCause : '--' }}”事宜， 已协调理赔人员联系投诉人处理。沟通协商达“
                        <el-form-item class="styleFormItem" label="" prop="unifyInfo">
                            <el-select class="width_60 styleInput" v-model="submitForm.unifyInfo" clearable placeholder="">
                                <el-option v-for="item in unifyInfoOptions" :key="item.dictName" :label="item.dictName" :value="item.dictName"> </el-option>
                            </el-select>
                        </el-form-item>
                        ”成一致意见。“
                        <el-form-item class="styleFormItem" label="" prop="leavingMarks">
                            <el-select class="width_75 styleInput" v-model="submitForm.leavingMarks" clearable placeholder="">
                                <el-option v-for="item in leavingMarksOptions" :key="item.dictName" :label="item.dictName" :value="item.dictName"> </el-option>
                            </el-select>
                        </el-form-item>
                        ”留痕已上传。
                    </div>
                </template>
                <template v-else>
                    <el-row class="content_row" style="margin-bottom: 15px">
                        <el-col class="col_txt" :span="7">是否办结：{{ caseDetailData && String(caseDetailData.closeCaseFlag) == 'true' ? '是' : caseDetailData && String(caseDetailData.closeCaseFlag) == 'false' ? '否' : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2" v-if="0"><span class="fontSeat">占</span>核赔人：{{ caseDetailData && caseDetailData.checkIndemnityPerson ? caseDetailData.checkIndemnityPerson : '--' }}</el-col>
                    </el-row>
                    <div class="situation">
                        投诉人来电投诉“{{ caseDetailData && caseDetailData.complaintCause ? caseDetailData.complaintCause : '--' }}”事宜，已协调理赔人员联系投诉人处理。沟通协商达“{{ caseDetailData && caseDetailData.unifyInfo ? caseDetailData.unifyInfo : '--' }}”成一致意见。“{{
                            caseDetailData && caseDetailData.leavingMarks ? caseDetailData.leavingMarks : '--'
                        }}”留痕已上传。
                    </div>
                </template></el-form
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: null
        },
        btnMenuData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            unifyInfoOptions: [],
            leavingMarksOptions: [],
            submitForm: {
                flag: 2,
                unifyInfo: '',
                leavingMarks: ''
            },
            submitRules: {
                checkIndemnityPerson: [{ required: true, message: '请输入', trigger: ['blur'] }],
                unifyInfo: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                leavingMarks: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }]
            },
            editForm: {
                complaintId: null,
                closeCaseFlag: false,
                checkIndemnityPerson: null
            }
        };
    },
    mounted() {
        // 是否达成一致
        this.getPublicEnum('COMPLAINT_UNIFY_INFO', 'unifyInfoOptions');
        // 信息留痕
        this.getPublicEnum('COMPLAINT_LEAVING_MARKS_INFO', 'leavingMarksOptions');
    },
    methods: {
        validateForm(submitType) {
            this.$refs.submitRef.validate((valid) => {
                if (valid) {
                    if (submitType == 1) {
                        this.$emit('submitFn', this.submitForm);
                    } else {
                        this.submitForm.flag = 22;
                        this.$emit('submitFn', this.submitForm);
                    }
                } else {
                    this.$message.error('处理情况内容不完整，不能提交案件！');
                    if (submitType == 1) {
                        this.$emit('submitFn', { flag: 3, submitFlag: false });
                    } else {
                        this.$emit('submitFn', { flag: 33, submitFlag: false });
                    }
                }
            });
        },
        editCaseDetail(update, event, parentData) {
            if (!parentData) {
                this.$emit('getEditDetailForm');
                return;
            }

            let editFormData = { ...parentData, ...this.editForm };

            this.instance.post('/complaintInfo/editComplaint', editFormData).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('编辑成功！');
                } else {
                    this.$message.error(data.msg);
                }
                // update && this.$emit('updatePageInfo');
            });
        },
        radioInput() {}
    },
    watch: {
        caseDetailData: {
            handler(val) {
                if (val) {
                    this.editForm = {
                        complaintId: val.id,
                        closeCaseFlag: val.closeCaseFlag,
                        checkIndemnityPerson: val.checkIndemnityPerson
                    };

                    if (!this.submitForm.leavingMarks && !this.submitForm.unifyInfo) {
                        this.submitForm.leavingMarks = val.leavingMarks || '';
                        this.submitForm.unifyInfo = val.unifyInfo || '';
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        padding-bottom: 20px;
        .situation {
            display: flex;
            .submit_form {
                display: flex;
                align-items: center;
                margin-top: -10px;
                .el-form-item {
                    margin-bottom: 0px;
                    position: relative;
                }
                .styleInput {
                    /deep/ .el-input__inner {
                        height: 25px;
                        line-height: 24px;
                    }
                }
            }

            .el-form-item {
                margin-bottom: 0px;
                position: relative;
                top: -8px;
            }
            .styleFormItem {
                // margin-left: -90px;
            }
        }
        .content_row {
            margin-bottom: 0;
            .personCol {
                display: flex;
                .personDiv {
                    padding-top: 5px;
                    width: 62px;
                }
                .personFormItem {
                    width: 160px;
                }
            }
            .edit_input {
                width: 160px !important;
            }
        }
    }
}
</style>